import React from 'react';
import {Card, CardContent} from "@mui/material";
import {Link} from "react-router-dom";


export default function HomePage() {

    return (
        <Card>
            <CardContent>
                <h1 >
                    orbit Sensorfusion GmbH
                    <br/>
                    Management der orbit-Multisensorsysteme
                </h1>

                Nehmen Sie hier Einstellungen für Ihre Sensorknoten und Sensoren der orbit-Multisensorsysteme vor.

            </CardContent>
        </Card>
    );

};

