import React from 'react';
import {AppBar, Container, Typography} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {recadero_api} from "../config";

export default function Footer(props) {

    return (
        <AppBar position="static" color="secondary">
            <Container maxWidth="md">
                <Toolbar>
                    <Typography variant="body1" color="inherit">
                        © 2024 orbit Sensorfusion GmbH
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

