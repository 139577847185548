import { createSlice } from '@reduxjs/toolkit'

export const sensorListSlice = createSlice({
    name: 'sensorList',
    initialState: {
        loaded: false,
        loading: false,
        items: null,
        gid: -1,
        nid: -1,
    },
    reducers: {
        loadSensorList: (state,{payload}) => {
            state.loaded = false
            state.loading = true
            state.items = null
            state.gid = payload.gid
            state.nid = payload.nid
        },

        loadedSensorList: (state, {payload})=>{
            state.loaded = true
            state.loading = false
            state.items = []
            Object.entries(payload).forEach(([id,sensor])=>{
                state.items[id]= sensor
            })
        },

        unloadSensorList: (state,{payload}) => {
            state.loaded = false
            state.loading = false
            state.items = null
            state.gid = -1
            state.nid = -1
        },

        updateSensorList: (state, {payload}) => {

        },
        updateSensorListDone: (state, {payload}) => {

        }
    }
})

export const {
    loadSensorList, loadedSensorList, unloadSensorList,
    updateSensorList, updateSensorListDone,

} = sensorListSlice.actions

export default sensorListSlice.reducer
