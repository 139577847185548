import {useNavigate, useParams} from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    InputLabel,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField, Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {dispatchToStore} from "../../store";
import {loadNodeList} from "../../store/slices/nodeList";
import Box from "@mui/material/Box";
import Switch from '@mui/material/Switch';
import {loadGatewayList} from "../../store/slices/gatewayList";
import {loadSensorList, unloadSensorList, updateSensorList} from "../../store/slices/sensorList";
import MenuItem from "@mui/material/MenuItem";
import {CardNavigation} from "../../components/CardNavigation";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const sensorListLookup = {
    "Fuel Gauge": {
        "dataSizeInBytes": (1 * 4) + 1,
        "sampleTimes": {
            0: "None",
        }
    },

    "Accelerometer": {
        "dataSizeInBytes": (3 * 4) + 1,
        "sampleTimes": {
            80000: "80ms",
            40000: "40ms",
            20000: "20ms",
            10000: "10ms",
            5000: "5ms",
            2500: "2.5ms",
            1250: "1.25ms"
        }
    },

    "Gyroscope": {
        "dataSizeInBytes": (3 * 4) + 1,
        "sampleTimes": {40000: "40ms", 20000: "20ms", 10000: "10ms", 5000: "5ms", 2500: "2.5ms", 1250: "1.25ms"}
    },

    "Magnetometer": {
        "dataSizeInBytes": (3 * 4) + 1,
        "sampleTimes": {
            500000: "500ms",
            100000: "100ms",
            50000: "50ms",
            33300: "33.3ms",
            20000: "20ms",
            10000: "10ms",
            6670: "6.67ms",
            5000: "5ms"
        }
    },

    "Ambient Pressure": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {
            10000000: "10s",
            5000000: "5s",
            1000000: "1s",
            500000: "500ms",
            250000: "250ms",
            100000: "100ms",
            50000: "50ms",
            20000: "20ms"
        }
    },

    "Thermometer": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {
            10000000: "10s",
            5000000: "5s",
            1000000: "1s",
            500000: "500ms",
            250000: "250ms",
            100000: "100ms",
            50000: "50ms",
            20000: "20ms"
        }
    },

    "Relative Humidity Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {
            10000000: "10s",
            5000000: "5s",
            1000000: "1s",
            500000: "500ms",
            250000: "250ms",
            100000: "100ms",
            50000: "50ms",
            20000: "20ms"
        }
    },

    "CO2 Concentration Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000000: "60s", 10000000: "10s", 1000000: "1s"}
    },

    "IAQ Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000000: "60s", 10000000: "10s", 1000000: "1s"}
    },

    "BVOC Concentration Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000000: "60s", 10000000: "10s", 1000000: "1s"}
    },
    "TVOC Concentration Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000000: "60s", 10000000: "10s", 1000000: "1s"}
    },

    "Ambient Light Sensor": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {
            60000000: "60s",
            10000000: "10s",
            1000000: "1s",
            800000: "800ms",
            500000: "500ms",
            250000: "250ms",
            125000: "125ms"
        }
    },
    "Barometer": {
        "dataSizeInBytes": (4) + 1,
        "sampleTimes": {60000000: "60s", 10000000: "10s", 1000000: "1s"}
    },
    "Vibration": {
        "dataSizeInBytes": (3 * 4) + 1,
        "sampleTimes": {
            1280000: "1.28s",
            640000: "640ms",
            320000: "320ms",
            160000: "160ms",
            80000: "80ms",
            40000: "40ms",
            20000: "20ms",
            10000: "10ms",
            5000: "5ms",
            2500: "2.5ms",
            1250: "1.25ms",
            625: "0.625ms",
            312: "0.3125ms",
            156: "0.15625ms",
             78: "0.078125ms",
             39: "0.0390625ms",
        }
    }
}

const raw_processor = "RAW"
const rms_avg_processor = "RMS_AVG"


function getSampleTimes(sensor) {
    if (Object.keys(sensorListLookup).includes(sensor)) {
        return sensorListLookup[sensor].sampleTimes
    } else {
        return []
    }
}

function getSampleTimeStr(sensor, sampleTime) {
    if (Object.keys(sensorListLookup).includes(sensor)) {
        if (Object.keys(sensorListLookup[sensor].sampleTimes).includes(sampleTime)) {
            return sensorListLookup[sensor].sampleTimes[sampleTime]
        }
    }
    return 0
}

export function getSampleTime(sensor, sampleTime) {
    if (Object.keys(sensorListLookup).includes(sensor)) {
        if (Object.keys(sensorListLookup[sensor].sampleTimes).includes(sampleTime)) {
            return sensorListLookup[sensor].sampleTimes[sampleTime]
        }
    }
    return 0
}

export function SampleTimeSelector(prop) {

    const [sampleTime, setSampleTime] = useState(String(prop.sample_period))

    const handleChange = (event) => {
        const sampleTime = event.target.value
        console.debug("selected", sampleTime)
        setSampleTime(sampleTime)
        prop.onHandleChange(prop.id, parseInt(sampleTime))
    };

    const validate = s => {

        if (Object.keys(sensorListLookup).includes(prop.sensor)) {
            if (Object.keys(sensorListLookup[prop.sensor].sampleTimes).includes(s)) {
                console.debug("validation success", s)
                return s
            }
        }
        if (prop.sensor === undefined){
            console.warn(prop.sensor,"unknown sensor")
            return "None"
        }
        if (sensorListLookup[prop.sensor] === undefined){
            console.warn(prop.sensor, "missing lookup")
            return "None"
        }
        const suggestedSampleTime = Object.keys(sensorListLookup[prop.sensor].sampleTimes).pop()
        console.warn(prop.sensor, "validation of sampleTime failed", s, "suggest to take", suggestedSampleTime)
        return "None"
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="update_time_select">Sample Time</InputLabel>
            <Select
                disabled={prop.disabled}
                value={validate(sampleTime)}
                label={"update_time"}
                onChange={handleChange}

            >
                <MenuItem value={"None"}>None</MenuItem>

                {Object.entries(getSampleTimes(prop.sensor)).map(([st, str]) =>
                    <MenuItem value={st} key={st}>{str}</MenuItem>
                )}
            </Select>
        </FormControl>
    )
}

function PostProcessorSelector(props) {
    const [open, setOpen] = useState(false)

    return (
        <Stack direction={"column"} spacing={2} >
            <Stack direction={"row"} justifyContent={"space-between"}>
                <FormControl>
                    <InputLabel id={"selected_post_processor_label"}>Active Post Processor</InputLabel>
                    <Select
                        labelId={"selected_post_processor_label"}
                        id={"selected_post_processor"}

                        value={props.selected}
                        label={"Active Post Processor"}
                        onChange={(event) => {
                            props.onSelectChange(props.id, event.target.value)
                        }}
                        color={"primary"}
                    >
                        <MenuItem value={raw_processor}>Raw Values (no processing, direct output)</MenuItem>
                        <MenuItem value={rms_avg_processor}>Rms Values (avg over N-values)</MenuItem>

                    </Select>
                </FormControl>
                <Button onClick={() => setOpen(!open)}>
                    {open ?
                        <ExpandLessIcon/>
                        :
                        <ExpandMoreIcon/>
                    }

                </Button>
            </Stack>

            {open ?
                <Stack>
                    {props.selected === raw_processor ?
                        <Typography>no properties available</Typography>
                        : ""}

                    {props.selected === rms_avg_processor ?
                        <TextField id="avg_count" type="number" variant="outlined" label="Number of Averaging Values"
                                   value={props.config.avg_count}
                                   onChange={(event) => props.onHandleChange(props.id, props.selected, {avg_count: Number(event.target.value)})}/>
                        : ""}
                </Stack>
                :
                <></>
            }
        </Stack>
    )
}

function EnabledSwitch(props) {

    return (
        <Switch
            disabled={props.disabled}
            checked={props.enabled}
            onChange={props.onChange}
        />
    )

}


export default function SensorListPage() {

    const {gid, nid} = useParams()

    let navigate = useNavigate();

    const gateways = useSelector(state => state.gatewayList.items)
    const nodeList = useSelector(state => state.nodeList)
    const sensorList = useSelector(state => state.sensorList)

    const gateway = gateways[gid]
    const node = nodeList.items[nid]

    const [sensors, setSensors] = useState(null)
    const [modified, setModified] = useState(false)

    useEffect(() => {
        // on page load

        setSensors(null)
        setModified(false)

        dispatchToStore(loadGatewayList())
        dispatchToStore(loadNodeList({gid: gid}))
        dispatchToStore(loadSensorList({gid: gid, nid: nid}))

        return () => {
            dispatchToStore(unloadSensorList())
        }

    }, []);

    useEffect(() => {

        if (!sensorList.loaded) {
            return
        }

        if ((sensorList.gid !== gid) || (sensorList.nid !== nid)) {
            return
        }

        if (sensors === null) {
            setSensors({...sensorList.items})
        }

        return () => {
        }

    }, [sensorList, sensors]);

    function onChangeEnable(name, newValue) {
        const s = {...sensors}
        s[name].auto_connect = newValue
        setSensors(s)
        setModified(true)
    }

    function onHandleChange(sensor_name, sample_time) {
        const s = {...sensors}
        s[sensor_name].sample_period = sample_time
        setSensors(s)
        setModified(true)
    }

    function onHandleSelectPostProcessor(sensor_name, post_processor_name) {
        console.log(sensor_name, post_processor_name)
        const s = {...sensors}
        s[sensor_name].post_processor.name = post_processor_name
        setSensors(s)
        setModified(true)
    }

    function onHandleConfigPostProcessor(sensor_name, post_processor_name, post_processor_config) {
        const s = {...sensors}
        s[sensor_name].post_processor[post_processor_name.toLowerCase()] = post_processor_config
        setSensors(s)
        setModified(true)
    }

    function navigateTo(gid) {
        const target = "/gateways/" + gid + "/nodes" + "/view"
        console.log("move to " + target)
        navigate(target, {replace: true})
    }

    function onHandleApply() {
        console.debug(sensors)

        dispatchToStore(updateSensorList({
            gid: gid,
            nid: nid,
            sensors: sensors,
        }))

        navigateTo(gid, nid)
    }

    const pageLoading = (gateway === undefined) || (node === undefined) || (sensors === null)

    return (
        <Container>
            <Card style={{backgroundColor: "#c5c5c5"}}>
                <CardContent>

                    {!pageLoading &&
                        <CardNavigation
                            breadCrumbs={[gateway.description, node.mac]}
                            mode={gateway.config_mode ? "config" : "run"}
                            title={"Sensor List"}
                            linkBack={"/gateways/" + gid + "/nodes" + "/view"}
                        />}

                    <Stack direction={"column"} alignContent={"flex-end"}>
                        <Button variant={"contained"} onClick={() => {
                            onHandleApply()
                        }} disabled={!modified}>
                            Apply Changes
                        </Button>
                    </Stack>

                    <Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="center">Enabled</TableCell>
                                        <TableCell align="center">Sample Period</TableCell>
                                        <TableCell align="center">Post Processor</TableCell>
                                    </TableRow>
                                </TableHead>

                                {!pageLoading &&
                                    <TableBody>
                                        {Object.entries(sensors).map(([sensor_name, sensor]) => {
                                            return (
                                                <TableRow key={sensor_name}>
                                                    <TableCell>{sensor_name}</TableCell>

                                                    <TableCell align="center">
                                                        <EnabledSwitch
                                                            enabled={sensor.auto_connect}
                                                            disabled={nodeList.busy || !gateway.config_mode}
                                                            onChange={() => {
                                                                onChangeEnable(sensor_name, !sensor.auto_connect)
                                                            }}/>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <SampleTimeSelector
                                                            id={sensor_name}
                                                            disabled={nodeList.busy || !gateway.config_mode}
                                                            sensor={sensor_name}
                                                            sample_period={sensor.sample_period}

                                                            onHandleChange={onHandleChange}
                                                        />

                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <PostProcessorSelector
                                                            id={sensor_name}
                                                            selected={sensor.post_processor.name}
                                                            config={sensor.post_processor[sensor.post_processor.name.toLowerCase()]}
                                                            onSelectChange={onHandleSelectPostProcessor}
                                                            onHandleChange={onHandleConfigPostProcessor}
                                                        />

                                                    </TableCell>

                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                }

                            </Table>
                        </TableContainer>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    );

};
