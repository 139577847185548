import React, {useEffect} from 'react';
import {
    Button,
    Card,
    CardContent, CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Tooltip, Typography, useTheme
} from "@mui/material";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {dispatchToStore} from "../../store";
import {loadGatewayList, updateGatewayListMode} from "../../store/slices/gatewayList";
import {getRelativeTime} from "../../utils/relativeTime";
import SettingsIcon from "@mui/icons-material/Settings";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import BluetoothDisabledIcon from "@mui/icons-material/BluetoothDisabled";
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {updateNodeListProp} from "../../store/slices/nodeList";
import Switch from "@mui/material/Switch";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {config} from "../../config";

function IsConnected(props){
    const theme = useTheme();
    if (props.connected){
        return(<WifiIcon style={{color: theme.status.connected}}/>)
    } else {
        return(<WifiOffIcon style={{color: theme.status.not_connected}}/>)
    }
}

function EnabledSwitch(props){

    function handleChange(){
        dispatchToStore(updateGatewayListMode({
            gid: props.gid,
            mode: props.enabled ? "RUN" : "CONFIG",
        }))
    }

    if (props.applied) {
        return (
            <Switch
                checked={props.enabled}
                onChange={handleChange}
            />
        )
    } else {
        return (
            <CircularProgress />
        )
    }
}


export default function GatewayListPage() {

    let navigate = useNavigate();

    const theme = useTheme();

    const gateways = useSelector(state => state.gatewayList.items)

    useEffect(() => {
        // on page load
        console.log("pageLoad")
        update()
        const interval = setInterval(()=>{update()}, config.updateInterval)

        return ()=>{
            // on page leave
            console.log("pageUnload ", interval)
            clearInterval(interval)
        }

    }, []);

    function update(){
        dispatchToStore(loadGatewayList())
    }

    function showGlobalSettings(device_id){
        const target = "/gateways/" + device_id + "/settings" + "/view"
        console.log("move to " + target)
        navigate(target, {replace: true})
    }

    function showNodeList(device_id){
        const target = "/gateways/" + device_id + "/nodes" + "/view"
        console.log("move to " + target)
        navigate(target, {replace: true})
    }

    return (
        <Card style={{backgroundColor: "#c5c5c5"}}>
            <CardContent>
                <h1>Gateway List</h1>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">Connected</TableCell>
                                <TableCell align="center">Config Mode</TableCell>
                                <TableCell align="center">Last Contact</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            { Object.entries(gateways).map(([id, item]) => { return (
                                <TableRow key={item.gid} >
                                    <TableCell>{item.description} <Typography variant={"body2"} >{item.serial_number}</Typography></TableCell>
                                    <TableCell align="center"><IsConnected connected={item.connected}/></TableCell>
                                    <TableCell align="center"><EnabledSwitch gid={item.gid} enabled={item.config_mode} applied={item.settings_applied}/></TableCell>
                                    <TableCell align="center">{item.last_contact}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={"Node List"}>
                                            <Button color="primary" onClick={(e)=>{showNodeList(item.gid)}} >
                                                {item.config_mode ?
                                                    <SettingsIcon />
                                                    :
                                                    <VisibilityIcon />
                                                }

                                            </Button>
                                        </Tooltip>

                                    </TableCell>

                                </TableRow>
                            ) } )}


                        </TableBody>
                    </Table>
                </TableContainer>

            </CardContent>
        </Card>
    );

};

