import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Button, Card, CardContent, Container, Paper, Stack, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {dispatchToStore} from "../../store";
import {loadGatewayList} from "../../store/slices/gatewayList";
import {loadNodeList, updateNodeListConfig} from "../../store/slices/nodeList";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {TimePicker} from "@mui/x-date-pickers";
import Cron from "react-js-cron";
import 'react-js-cron/dist/styles.css'
import Switch from "@mui/material/Switch";
import {CardNavigation} from "../../components/CardNavigation";

export default function NodeConfigPage() {

    const {gid, nid} = useParams()

    let navigate = useNavigate();
    const nodeList = useSelector(state => state.nodeList)
    const gateways = useSelector(state => state.gatewayList.items)

    const gateway = gateways[gid]

    const [modified, setModified] = useState(false)
    const [config, setConfig] = useState(undefined)
    const [isBusy, setIsBusy] = useState(true)
    const [date, setData] = useState('')


    const [powerSaverEnabled, setPowerSaverEnabled] = useState(false)

    useEffect(() => {
        setConfig(undefined)
        setModified(false)
        dispatchToStore(loadGatewayList())
        dispatchToStore(loadNodeList({gid:gid}))

        return ()=>{
            // on page leave
        }

    }, []);

    useEffect(() => {
        if (!nodeList.loaded){
            return
        }

        // create a copy
        setConfig({...nodeList.items[nid].config})

        if (nodeList.items[nid].config.power_saver.on_time_sec !== 0){
            setPowerSaverEnabled(true)
        } else {
            setPowerSaverEnabled(false)
        }

        setModified(false)

    }, [nodeList.loaded]);

    function navigateTo(gid){
        const target = "/gateways/" + gid + "/nodes" + "/view"
        console.log("move to " + target)
        navigate(target, {replace: true})
    }

    function onHandleApply(){
        if (!nodeList.loaded){
            return
        }

        setModified(false)

        dispatchToStore(updateNodeListConfig({
            gid: gid,
            nid: nid,
            config: config,
        }))

        navigateTo(gid)
    }

    function togglePowerSaver(){
        const newPowerSaverEnabledState = !powerSaverEnabled

        if (newPowerSaverEnabledState) {
            setConfig({
                ...config,
                power_saver: {...config.power_saver, on_time_sec: Number(60)}
            })
        } else {
            setConfig({
                ...config,
                power_saver: {...config.power_saver, on_time_sec: Number(0)}
            })

        }

        setPowerSaverEnabled(newPowerSaverEnabledState)
        setModified(true)
    }

    if (gateway === undefined){
        return <></>
    }

    if (config === undefined){
        return (<></>)
    }

    return (
        <Container>
            <Card style={{backgroundColor: "#c5c5c5"}}>
                <CardContent>

                    <CardNavigation
                        breadCrumbs={[gateway.description, nodeList.items[nid].mac]}
                        mode={gateway.config_mode?"config":"run"}
                        title={"Node Config"}
                        linkBack={"/gateways/" + gid + "/nodes" + "/view"}
                    />

                    <Stack direction={"column"} alignContent={"flex-end"}>
                        <Button variant={"contained"} onClick={()=>{onHandleApply()}} disabled={!modified}>
                            Apply Changes
                        </Button>
                    </Stack>


                    <Box>
                        <Paper>

                            <Stack spacing={2} sx={{padding:2}}>
                                <Typography>Power Saver</Typography>

                                <Switch
                                    checked={powerSaverEnabled}
                                    onChange={togglePowerSaver}
                                />

                                <TextField
                                    id="on_time_sec"
                                    type="number"
                                    variant="outlined"
                                    label="On Time [sec]"
                                    disabled={!powerSaverEnabled}
                                    value={config.power_saver.on_time_sec}
                                    onChange={(e)=> {
                                        setConfig({
                                            ...config,
                                            power_saver: {...config.power_saver, on_time_sec: Number(e.target.value)}
                                        })
                                        setModified(true)
                                    }}
                                />

                                <TextField
                                    id="cron_value"
                                    type="string"
                                    variant="outlined"
                                    label="Cron Value"
                                    disabled={true}
                                    value={config.power_saver.cron_value}
                                />

                                <Cron
                                    value={config.power_saver.cron_value}

                                    setValue={(v)=> {

                                        setConfig({
                                        ...config,
                                        power_saver: {...config.power_saver, cron_value: v}})

                                        if (v !== config.power_saver.cron_value) {
                                            setModified(true)
                                        }
                                    }}

                                    disabled={!powerSaverEnabled}
                                />

                            </Stack>
                        </Paper>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    )
}

/*
                                <hr />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                            ampm={false}
                                            label="Basic date time picker"
                                            views={['hours', 'minutes', 'seconds']}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>

 */