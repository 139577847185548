
export const recadero_api = "0.96"

export const config = {
    version: "v0.4.4",
    updateInterval: 1000,
}

export function isDebugEnvironment(){
    if (process.env.NODE_ENV !== 'production'){
        return true
    }
    return false
}


let backend_api_url = "http://localhost:8099/web/api.php"
if (!isDebugEnvironment()) {
    const host = window.location.host
    const protocol = window.location.protocol

    backend_api_url = protocol + "//" + host + "/recadero/web/api.php"
}
console.log("api url", backend_api_url)

export function getApiUrl(){
    return backend_api_url
}