import {useNavigate} from "react-router-dom";
import React from "react";
import {Grid, IconButton, Stack, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsIcon from "@mui/icons-material/Settings";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

function GatewayMode(props){
    const mode = props.mode

    switch(mode){
        case "config":
            return (
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                    <SettingsIcon />
                    Config Mode
                </Stack>
            )

        case "run":
            return (
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                    <PlayCircleOutlineIcon />
                    Run Mode
                </Stack>
            )
    }

    return(
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
            Failed mode
        </Stack>
    )
}

export function CardNavigation(props) {

    const breadCrumbs = props.breadCrumbs
    const mode = props.mode
    const title = props.title
    const linkBack = props.linkBack

    let navigate = useNavigate();

    function navigateTo() {
        const target = linkBack
        console.log("move to " + target)
        navigate(target, {replace: true})
    }

    const crumbs = []
    Object.entries(breadCrumbs).forEach(([key, value]) => {
        crumbs.push(<Typography key={key} variant={"subtitle1"}>{value}</Typography>)
    })

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container>

                    <Grid item xs={4} >
                        <Stack direction={"column"}>
                            {crumbs}
                        </Stack>
                    </Grid>

                    <Grid item xs={4} textAlign={"center"}>
                        <GatewayMode mode={mode}/>
                    </Grid>

                    <Grid item xs={4} textAlign={"right"}>
                        <IconButton onClick={(e) => {
                            navigateTo()
                        }}>
                            <ArrowBackIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} textAlign={"center"}>
                <Stack direction={"row"} justifyContent={"center"}>
                    <Typography variant={"h5"}>{title}</Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}